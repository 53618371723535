body {
  background-color: #d1c39e;
  /*background-color: lightblue;*/
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*cursor: url("cursor.svg"), auto !important;*/
  cursor: none;
}

*:hover, *:active, *:focus, *:visited, *:link {
  /*cursor: url("cursor.svg"), auto !important;*/
  cursor: none;
}

p, h1, h2, h3, h4, li, ul, a, button, div {
  letter-spacing: 0.02em;
  color: #38383a;
}

@media screen and (max-width: 1024px)
{
  body {
    cursor: auto;
  }
  *:hover, *:active, *:focus, *:visited, *:link {
    /*cursor: url("cursor.svg"), auto !important;*/
    cursor: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #d1c39e;
  /*border: 1px solid #38383a;*/
}

::-webkit-scrollbar-thumb {
  background: #38383a;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(56,56,58,0.7);
}