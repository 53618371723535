.cursor {
    position: fixed;
    top: -1rem;
    left: -1rem;
    border-radius: 50%;
    pointer-events: none;
    z-index: 1000;
}
.cursor div {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    border: 1px solid #38383a;
    -webkit-transition: opacity 0.35s ease, -webkit-transform 0.5s ease;
    transition: opacity 0.35s ease, -webkit-transform 0.5s ease;
    transition: transform 0.5s ease, opacity 0.35s ease;
    transition: transform 0.5s ease, opacity 0.35s ease, -webkit-transform 0.5s ease;
}
.cursor.is-active div {
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0.25;
}

@media screen and (max-width: 1024px)
{
   .cursor {
       display: none !important;
   }
}